import locale9ec66352 from '../../translations/en.json'
import locale6c3fbcfd from '../../translations/ru.json'
import locale0c3c0eec from '../../translations/uk.json'
import locale3ff3e283 from '../../translations/he.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: (context) => import('/data/main/node_modules/@inyur/nuxt-module-i18n/build/vue-i18n.js').then(m => m.default(context)),
  vueI18nLoader: true,
  locales: [{"code":"en","text":"English","nativeText":"English","isCatchallLocale":true,"flag":"gb","file":"en.json"},{"code":"ru","text":"Russian","nativeText":"Русский","momentLocale":"ru","flag":"ru","file":"ru.json","fallback":["en"]},{"code":"uk","text":"Ukrainian","nativeText":"Українська","momentLocale":"uk","flag":"ua","file":"uk.json","fallback":["ru","en"]},{"code":"he","text":"Hebrew","nativeText":"עִברִית","momentLocale":"he","dir":"rtl","flag":"il","file":"he.json","fallback":["en"]}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: "translations/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: function (oldLocale, newLocale, initialSetup, context) {
      /**
       * Resolve initial language
       * options{} located in rendered template of nuxt-i18n
       * this function pasted as is to nuxt-i18n options template
       * and located in .nuxt/nuxt-18n/options.js
       */
      if (initialSetup) {
        const {
          getLocaleSync
        } = require('@inyur/nuxt-module-i18n/build/init-language.js');
        return getLocaleSync(context, {
          strategy: options.strategy,
          routesNameSeparator: options.routesNameSeparator
        });
      }
    },
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","text":"English","nativeText":"English","isCatchallLocale":true,"flag":"gb","file":"en.json"},{"code":"ru","text":"Russian","nativeText":"Русский","momentLocale":"ru","flag":"ru","file":"ru.json","fallback":["en"]},{"code":"uk","text":"Ukrainian","nativeText":"Українська","momentLocale":"uk","flag":"ua","file":"uk.json","fallback":["ru","en"]},{"code":"he","text":"Hebrew","nativeText":"עִברִית","momentLocale":"he","dir":"rtl","flag":"il","file":"he.json","fallback":["en"]}],
  localeCodes: ["en","ru","uk","he"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "t",
  16: "r",
  17: "a",
  18: "n",
  19: "s",
  20: "l",
  21: "a",
  22: "t",
  23: "i",
  24: "o",
  25: "n",
  26: "s",
  27: "/",
  28: "e",
  29: "n",
  30: ".",
  31: "j",
  32: "s",
  33: "o",
  34: "n",
  35: "\"",
  36: ",",
  37: "\"",
  38: "r",
  39: "u",
  40: ".",
  41: "j",
  42: "s",
  43: "o",
  44: "n",
  45: "\"",
  46: ":",
  47: "\"",
  48: ".",
  49: ".",
  50: "/",
  51: "t",
  52: "r",
  53: "a",
  54: "n",
  55: "s",
  56: "l",
  57: "a",
  58: "t",
  59: "i",
  60: "o",
  61: "n",
  62: "s",
  63: "/",
  64: "r",
  65: "u",
  66: ".",
  67: "j",
  68: "s",
  69: "o",
  70: "n",
  71: "\"",
  72: ",",
  73: "\"",
  74: "u",
  75: "k",
  76: ".",
  77: "j",
  78: "s",
  79: "o",
  80: "n",
  81: "\"",
  82: ":",
  83: "\"",
  84: ".",
  85: ".",
  86: "/",
  87: "t",
  88: "r",
  89: "a",
  90: "n",
  91: "s",
  92: "l",
  93: "a",
  94: "t",
  95: "i",
  96: "o",
  97: "n",
  98: "s",
  99: "/",
  100: "u",
  101: "k",
  102: ".",
  103: "j",
  104: "s",
  105: "o",
  106: "n",
  107: "\"",
  108: ",",
  109: "\"",
  110: "h",
  111: "e",
  112: ".",
  113: "j",
  114: "s",
  115: "o",
  116: "n",
  117: "\"",
  118: ":",
  119: "\"",
  120: ".",
  121: ".",
  122: "/",
  123: "t",
  124: "r",
  125: "a",
  126: "n",
  127: "s",
  128: "l",
  129: "a",
  130: "t",
  131: "i",
  132: "o",
  133: "n",
  134: "s",
  135: "/",
  136: "h",
  137: "e",
  138: ".",
  139: "j",
  140: "s",
  141: "o",
  142: "n",
  143: "\"",
  144: "}",
}

export const localeMessages = {
  'en.json': () => Promise.resolve(locale9ec66352),
  'ru.json': () => Promise.resolve(locale6c3fbcfd),
  'uk.json': () => Promise.resolve(locale0c3c0eec),
  'he.json': () => Promise.resolve(locale3ff3e283),
}
