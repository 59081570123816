<template>
  <div>
    <Nuxt />
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'Layout',
};

</script>

<style></style>
