const packageJson = {
  name: '@inyur/nuxt-module-headers',
};
const metaApp = '_' + packageJson.name + '[meta-app]';
const metaUpdateFn = '_' + packageJson.name + '[update-fn]';

export default function (ctx) {
  ctx.app.mixins = ctx.app.mixins || [];

  ctx.app.mixins.push({
    // Here 'head:{}' or 'head(){}' don`t work at all
    created() {
      this[metaApp] = this.$meta().addApp(packageJson.name);
      // Here titleTemplate don`t work at all
      this[metaUpdateFn]();
    },
    beforeMount() {
      this[metaUpdateFn](); // Fix client side only render issue in missing attributes sometimes
    },
    methods: {
      [metaUpdateFn]() {
        this[metaApp].set({
          // title: 'InYur.com',
          // title: 'InYur',
          meta: [
            /* {
              name: 'description',
              // TODO Translate it
              content:
                (this.$i18n.locale === 'ru' &&
                  'Глобальная система онлайн-бронирования рабочих мест') ||
                (this.$i18n.locale === 'uk' &&
                  'Глобальна система онлайн-бронювання робочих місць') ||
                (this.$i18n.locale === 'uk' &&
                  'מערכת הזמנות מקוונת גלובלית של חללי עבודה') ||
                'Global online booking of working spaces',
            }, */
            { charset: 'utf-8' },
            { httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8' },
            // TODO Ставить такой viewport только на мобилке чтоб на таблетке скейлилось
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1',
            },
            // { name: 'viewport', content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0, user-scalable=yes' },
            // { name: 'viewport', content: 'width=device-width, initial-scale=1, minimum-scale=1, user-scalable=yes' },
            // { hid: 'description', name: 'description', content: '' },
            // { httpEquiv: 'X-UA-Compatible', content: 'IE=edge' },

            // https://developers.google.com/search/docs/crawling-indexing/block-indexing#meta-tag
            //
            { name: 'robots', content: 'noindex, nofollow, noarchive' },
            //

            // Favicons
            // https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/ConfiguringWebApplications/ConfiguringWebApplications.html
            // Затестить
            { name: 'apple-mobile-web-app-title', content: 'InYur' }, // Не работает
            { name: 'apple-mobile-web-app-capable', content: 'yes' }, // Возможно не работает
            {
              name: 'apple-mobile-web-app-status-bar-style',
              content: 'black-translucent',
            }, // Не работает
            // End Затестить

            // https://habr.com/ru/post/330584/
            { name: 'application-name', content: 'InYur' },

            // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/meta/name/theme-color #}
            // https://developers.google.com/web/fundamentals/design-and-ux/browser-customization#color_browser_elements #}
            // Работает на андроиде, проверено
            { name: 'theme-color', content: '#0084F4' },
            { name: 'google', value: 'notranslate' }, // https://www.w3.org/International/questions/qa-translate-flag
          ],
          link: [
            // { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
            // {# https://support.google.com/webmasters/answer/9290858?hl=ru#guidelines #}
            {
              rel: 'shortcut icon',
              type: 'image/svg',
              sizes: 'any',
              /* href: require(
                '!!file-loader?esModule=false&name=favicons/[name].[contenthash:5].[ext]!' +
                  '/data/main/node_modules/@inyur/nuxt-module-headers/src/favicons-prod/favicon.svg',
              ), */
              href: `https://${this.$config.CDN_DOMAIN || 'cdn.inyur.com'}/public-assets/inyur-favicons-v1/favicon.svg`,
            },
            //
            // {# https://1ps.ru/blog/dirs/2019/dlya-chego-ispolzuetsya-apple-touch-icon-v-2019-godu/ #}
            // {# iPad, iPhone #}
            {
              rel: 'apple-touch-icon',
              sizes: '180x180',
              /* href: require(
                '!!file-loader?esModule=false&name=favicons/[name].[contenthash:5].[ext]!' +
                  '/data/main/node_modules/@inyur/nuxt-module-headers/src/favicons-prod/apple-touch-icon-180х180.png',
              ), */
              href: `https://${this.$config.CDN_DOMAIN || 'cdn.inyur.com'}/public-assets/inyur-favicons-v1/apple-touch-icon-180х180.png`,
            },
            // https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/ConfiguringWebApplications/ConfiguringWebApplications.html
            // Затестить
            {
              rel: 'apple-touch-startup-image',
              /* href: require(
                '!!file-loader?esModule=false&name=favicons/[name].[contenthash:5].[ext]!' +
                  '/data/main/node_modules/@inyur/nuxt-module-headers/src/favicons-prod/apple-touch-icon-180х180.png',
              ), */
              href: `https://${this.$config.CDN_DOMAIN || 'cdn.inyur.com'}/public-assets/inyur-favicons-v1/apple-touch-icon-180х180.png`,
            }, // Не работает
            // Mac OS
            {
              rel: 'mask-icon',
              color: '#007eea',
              /* href: require(
                '!!file-loader?esModule=false&name=favicons/[name].[contenthash:5].[ext]!' +
                  '/data/main/node_modules/@inyur/nuxt-module-headers/src/favicons-prod/safari-pinned-tab.svg',
              ), */
              href: `https://${this.$config.CDN_DOMAIN || 'cdn.inyur.com'}/public-assets/inyur-favicons-v1/safari-pinned-tab.svg`,
            },
          ],
          /* style: [
            { cssText: 'html { font-size: 16px; }', type: 'text/css' }
          ], */
        });
      },
    },
  });
}
