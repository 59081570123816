/* eslint-disable no-unreachable, no-unused-vars */
import Vue from 'vue';
import Router from 'vue-router';
import { scrollBehavior } from '@inyur/nuxt-module-router';
import { parseURL, stringifyParsedURL } from 'ufo';

Vue.use(Router);

const routes = [
  {
    name: 'page-home',
    path: '/',
    component: () => import('./src/page-home').then(m => m.default),
  },
  {
    name: 'page-ci',
    path: '/ci',
    component: () => Promise.all([import('@inyur/vue-page-ci'), import('@inyur/vue-page-ci/dist/style.css')]).then(([m]) => m.default),
    props: {
      pages: [
        {
          url: '/en?ci.product-published=true',
          title: 'Product published',
          text: '',
        },
        {
          url: '/en',
          title: 'Product not published',
          text: '',
        },
      ],
    },
  },
];

// Here we DON`T use route based locales.

export function createRouter() {
  const router = new Router({
    mode: 'history',
    routes,
    scrollBehavior,
  });

  return router;
}

function stripDomain(absoluteUrl) {
  const { pathname, search, hash } = parseURL(absoluteUrl);
  return stringifyParsedURL({ pathname, search, hash });
}
